import post from '@/api/base/no-authorization/post';
import addAuthorization from '@/api/base/token/addAuthorization';
/**
 * Make a POST request with authorization.
 * @param {string} url Route path.
 * @param {object} options Request options.
 */
export default async function (url, options) {
    const optionsWithAuth = await addAuthorization(options);
    return post(url, optionsWithAuth);
}
