import post from '@/api/base/with-authorization/post';
/**
 * Post venue services.
 *
 * @param {object} data new services to add.
 *
 * @returns {Promise}
 */
export default function (data) {
    return post('venue/services/bulk/', data);
}
