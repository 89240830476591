import patch from '@/api/base/with-authorization/patch';
/**
 * Patch venue services.
 *
 * @param {object} data new and removed Services.
 *
 * @returns {Promise}
 */
export default function (data) {
    return patch('venue/services/bulk/', data);
}
