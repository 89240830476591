import patch from '@/api/base/no-authorization/patch';
import addAuthorization from '@/api/base/token/addAuthorization';
/**
 * Make a PATCH request with authorization.
 * @param {string} url Route path.
 * @param {object} options Request options.
 */
export default async function (url, options = {}) {
    const optionsWithAuth = await addAuthorization(options);
    return patch(url, optionsWithAuth);
}
