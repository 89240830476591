import request from '@/api/base/request';
/**
 * Make a POST request.
 *
 * @param {string} url Route path.
 * @param {object} options Request options.
 *
 * @returns {Promise}
 */
export default function (url, options = {}) {
    return request(url, {
        ...options,
        method: 'POST'
    });
}
